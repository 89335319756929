<template>
  <div
    class="avatar"
    :style="{
      width: `${this.size}px`,
      height: `${this.size}px`
    }"
  >
    <div v-if="isLoading" class="skeleton loading-placeholder"></div>
    <img v-else :src="getSrc()" :alt="name" @error="setDefaultAvatar" />
  </div>
</template>

<script>
import { setDefaultAvatar } from "@/utils/image";
import UIAvatar from "@/utils/UIAvatar";

export default {
  components: {},
  mixins: [],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 64
    }
  },
  data: function () {
    return {
      setDefaultAvatar
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getSrc() {
      return this.src ? this.src : this.getUIAvatarImage();
    },
    getUIAvatarImage() {
      return new UIAvatar(this.name, { size: this.size }).url();
    }
  }
};
</script>

<style lang="scss">
.avatar {
  display: inline-block;
  img,
  .loading-placeholder {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
