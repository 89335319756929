/**
 * UI Avatars wrapper class
 * https://ui-avatars.com/
 */
export default class {
    name;
    uppercase;
    background;
    color;
    fontsize;
    bold;
    leng;
    rounded;
    size;

    option = [];

    constructor(name, options = null) {
        this.name = name;
        if (options !== null) {
            this.setOptions(options);
        }
    }

    setOptions(options) {
        this.uppercase = options.uppercase;
        this.background = options.background;
        this.color = options.color;
        this.fontsize = options.fontsize;
        this.bold = options.bold;
        this.length = options.length;
        this.rounded = options.rounded;
        this.size = options.size;
    }

    url() {
        let param = this.generateURLParam();
        let url = `https://ui-avatars.com/api/?name=${this.name}${param}`;
        return url;
    }

    generateURLParam() {
        this.option = []; // Clear option

        if (this.name === undefined || this.name === "") {
            throw "UIAvatar: 'name' cannot be empty";
        }
        if (this.uppercase !== undefined) {
            this.option.push(`&uppercase=${this.uppercase}`);
        }
        if (this.background !== undefined) {
            this.option.push(`&background=${this.background}`);
        }
        if (this.color !== undefined) {
            this.option.push(`&color=${this.color}`);
        }
        if (this.fontsize !== undefined) {
            this.option.push(`&fontsize=${this.fontsize}`);
        }
        if (this.bold !== undefined) {
            this.option.push(`&bold=${this.bold}`);
        }
        if (this.length !== undefined) {
            this.option.push(`&length=${this.length}`);
        }
        if (this.rounded !== undefined) {
            this.option.push(`&rounded=${this.rounded}`);
        }
        if (this.size !== undefined) {
            this.option.push(`&size=${this.size}`);
        }

        return this.option.join();
    }
}
